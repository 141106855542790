<!-- 球场订单 -->
<template>
  <div>
    <top-data></top-data>
    <div class="course-week mt-5">
        <div class="chooseMonth">
          <div>
            <span class="is_expired square"></span>
            <span class="title">可订</span>
          </div>
          <div>
            <span class="delay square"></span>
            <span class="title">过期</span>
          </div>
          <div>
            <span class="has_not_started square"></span>
            <span class="title">未安排</span>
          </div>
        </div>
      <div class="week_table">
        <div class="table_right_scroll_box">
          <div class="timeBox">
            <div class="xiang" @click="scollHandle(1)"><i class="el-icon-d-arrow-left"></i></div>
            <div class="scrollBox">
              <div
                class="list"
                :style="
                  'width:' +
                  7.8 * timeList.length +
                  'vw;transform: translateX(-' +
                  scrollLeft +
                  'vw);'
                "
              >
                <div
                  class="timeItem"
                  v-for="(item, index) in timeList"
                  :key="index"
                  @click="selectDay(index)"
                >
                  <div :class="'cart ' + (activeIndex == index ? 'active' : '')">
                    <div>{{ item.weekStr }}</div>
                    <div>{{ item.month }}月{{ item.day }}日</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="xiang" @click="scollHandle(2)"><i class="el-icon-d-arrow-right"></i></div>
          </div>
          <div class="venue_header">
            <div class="venue-item" v-for="venue in venueLists" :key="venue.venueId">
              <div class="venue-area">
                <div class="venue-name">{{ venue.venueName }}</div>
                <div class="venue-type">{{ venue.matchType }} | {{ venue.areaType }}</div>
              </div>
              <div class="schedule-list">
                <div v-for="(item, scheduleId) in venue.scheduleList" :key="scheduleId">
                  <div
                    class="detail-item"
                    :class="[
                      item.status != '0' ? 'delay' : '',
                      item.status === 0 ? 'can-order' : '',
                      item.status === -2 ? 'empty-order' : '',
                      item.status === 99 ? 'full' : '',
                    ]"
                    @click="editPlan(item, venue)"
                  >
                    <div>{{ item.beginTime }}-{{ item.endTime }}</div>
                    <div style="display: flex; align-items: center; margin-top: 8px">
                      <span
                        v-if="item.scheduleOrderType == 5"
                        class="pitch-style"
                        style="background-color: rgb(248, 211, 107)"
                      >
                        散客
                      </span>
                      <span
                        v-if="
                          item.scheduleOrderType == 0 &&
                          item.bookingDetailType != '1' &&
                          item.bookingDetailType != '-2'
                        "
                        class="pitch-style"
                        style="background-color: #2f7ec1"
                      >
                        整场
                      </span>
                      <span
                        v-if="
                          item.scheduleOrderType == 2 &&
                          item.bookingDetailType != '1' &&
                          item.bookingDetailType != '-2'
                        "
                        class="pitch-style"
                        style="background-color: #2f7ec1"
                      >
                        整场
                      </span>
                      <span
                        v-if="item.bookingDetailType == 1"
                        class="pitch-style"
                        style="background-color: #0a0a0a"
                      >
                        锁场
                      </span>
                      <span
                        v-if="item.scheduleOrderType == 4 || item.scheduleOrderType == 1"
                        class="pitch-style"
                        style="background-color: #fc4a3e"
                      >
                        约战
                      </span>
                      <span
                        v-if="item.bookingDetailType == -2"
                        class="pitch-style"
                        style="background-color: #aaaaaa"
                      >
                        未安排
                      </span>
                      <!-- <span
                        v-if="item.status === 0 && item.bookingDetailType !== -2 && item.bookingDetailType !== 1">可预定</span>
                      <span v-if="item.status === 1">即将开始</span>
                      <span v-if="item.status === 2">进行中</span>
                      <span v-if="item.status === 3">已结束</span>
                      <span v-if="item.status === 99">已订满</span>
                      <span v-if="item.status === -1">已取消</span> -->
                    </div>
                    <div
                      v-if="
                        item.scheduleOrderType == 2 &&
                        item.bookingDetailType != ' 1' &&
                        item.bookingDetailType != '-2'
                      "
                      class="orderType"
                    >
                      <span v-if="item.status == 99">已包场</span>
                      <span v-else>可包场/可约战</span>
                    </div>
                    <div
                      v-if="
                        item.scheduleOrderType == 0 &&
                        item.bookingDetailType != '1' &&
                        item.bookingDetailType != '-2'
                      "
                      class="orderType"
                    >
                      <span v-if="item.status == 99">已包场</span>
                      <span v-else>可包场/可约战</span>
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 8px">
                      <span v-if="item.status === 0 && item.bookingDetailType === 1"
                        >￥{{ item.price }}/人</span
                      >
                      <span v-if="item.status === 0 && item.bookingDetailType === 10"
                        >￥{{ item.price }}/人</span
                      >
                      <span v-if="item.status === 0 && item.bookingDetailType === 0"
                        >￥{{ item.price }}/场</span
                      >
                      <span v-if="item.status === -2 && item.bookingDetailType === 0"
                        >￥{{ item.price }}/场</span
                      >
                      <span v-if="item.status === 0 && item.bookingDetailType === 2"
                        >￥{{ item.price }}/队</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible" title="订单详情">
        <el-form label-width="auto" :model="formData">
          <el-form-item label="订单类型:">
            <div class="w-48"><el-input v-model="formData.bookType"></el-input></div>
          </el-form-item>
          <el-form-item label="时间:">
            <div class="w-48">
              <el-select clearable v-model="formData.time">
                <el-option
                  v-for="item in orderServeData.orderTime"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="状态:">
            <div class="w-48"><el-input v-model="formData.status"></el-input></div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button style="color: #134f7a" @click="conmitData()">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 编辑单个计划 -->
    <el-dialog :visible.sync="planVisible" title="编辑计划" width="30%" @click="handleCancelPlan">
      <el-form label-width="125px" :model="plan">
        <el-form-item label="开始时间：" prop="beginTime">
          <el-select size="small" clearable v-model="plan.beginTime">
            <el-option
              v-for="item in planTime.time"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结束时间：" prop="endTime">
          <el-select size="small" clearable v-model="plan.endTime">
            <el-option
              v-for="item in planTime.time"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型：" prop="bookingDetailType">
          <el-select
            size="small"
            v-model="plan.bookingDetailType"
            @change="changeBookingDetailType"
          >
            <el-option
              v-for="item of bookingDetailTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格：" prop="price">
          <span>￥</span>
          <el-input-number
            size="small"
            v-model="plan.price"
            :step="1"
            :precision="0"
            style="width: 160px; margin: 0 10px"
          ></el-input-number>
          <!-- 10散客 20飞盘 -->
          <span v-if="plan.bookingDetailType == 10 || plan.bookingDetailType == 20">/ 人</span>
          <!-- 0整场 1锁场 -->
          <span v-if="plan.bookingDetailType == 0 || plan.bookingDetailType == 1">/ 场</span>
          <!-- 2约战 -->
          <span v-if="plan.bookingDetailType == 2">/ 队</span>
        </el-form-item>
        <el-form-item label="人数：">
          <!-- 整场 -->
          <el-button type="text" v-if="plan.bookingDetailType == 0"> 人数不限 </el-button>
          <!-- 散客(拼场) 飞盘 -->
          <el-button
            type="text"
            title="编辑人数"
            v-if="plan.bookingDetailType == 10 || plan.bookingDetailType == 20"
            @click="changePersonAmount"
          >
            {{ plan.min }} --- {{ plan.max }}
          </el-button>
          <!-- 锁场-->
          <el-button type="text" v-if="plan.bookingDetailType == 1" @click="fieldLock">
            信息
          </el-button>
          <!-- 约战 -->
          <el-button type="text" v-if="plan.bookingDetailType == 2" @click="aboutWar">
            信息
          </el-button>
          <!-- 闲置 -->
          <el-button type="text" v-if="plan.bookingDetailType == -2"> -- </el-button>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            type="textarea"
            :rows="5"
            v-model="plan.remark"
            placeholder="请输入备注内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="handleCancelPlan">取消</el-button>
        <el-button
          size="small"
          type="primary"
          style="background-color: #134f7a; border: none"
          :disabled="saveBtnDisabled"
          @click="handleSavePlan"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!-- 人数弹窗 -->
    <el-dialog :visible.sync="personVisible" width="30%" title="人数设定">
      <el-form label-width="100px">
        <el-form-item label="最小人数：">
          <el-select size="small" clearable v-model="minAmount">
            <el-option
              class=""
              v-for="item in planTime.max"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最大人数：">
          <el-select size="small" clearable v-model="maxAmount">
            <el-option
              class=""
              v-for="item in planTime.max"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="handleCancelPerson" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="handleConfirmPerson">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 锁场弹窗 -->
    <el-dialog
      :visible.sync="lockVisible"
      width="50%"
      style="margin-top: 160px"
      title="预定信息"
      @close="closeLockDialog"
    >
      <el-form label-width="auto">
        <div class="group-name">
          <div class="group"></div>
          <div class="name">主队</div>
        </div>
        <el-row :gutter="20" class="mt-4">
          <el-col :span="8">
            <el-form-item label="球队名：">
              <el-input
                v-model="lockTeamList[0].teamName"
                size="small"
                placeholder="输入球队名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预定人：">
              <el-input
                v-model="lockTeamList[0].contact"
                size="small"
                placeholder="输入预定人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式：">
              <el-input
                v-model="lockTeamList[0].mobile"
                size="small"
                placeholder="输入联系方式"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="group-name">
          <div class="group"></div>
          <div class="name">客队</div>
        </div>
        <el-row :gutter="20" class="mt-4">
          <el-col :span="8">
            <el-form-item label="球队名：">
              <el-input
                v-model="lockTeamList[1].teamName"
                size="small"
                placeholder="输入球队名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预定人：">
              <el-input
                v-model="lockTeamList[1].contact"
                size="small"
                placeholder="输入预定人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式：">
              <el-input
                v-model="lockTeamList[1].mobile"
                size="small"
                placeholder="输入联系方式"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeLockDialog">取消</el-button>
        <el-button type="primary" size="small" @click="handleSaveLock">保存</el-button>
      </div>
    </el-dialog>

    <!-- 选择球队弹窗 -->
    <el-dialog
      :visible.sync="aboutWarVisible"
      width="50%"
      title="选择报名球队"
      @close="closeAboutWar"
    >
      <el-button type="primary" size="small" @click="addTeam" style="margin-bottom: 20px"
        >新增</el-button
      >
      <el-table :data="teamList" border stripe>
        <el-table-column width="35">
          <template slot-scope="scope">
            <el-radio :label="scope.row.teamId" v-model="radio">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="teamName" label="球队名称" align="center"> </el-table-column>
        <el-table-column prop="level" label="水平" align="center"> </el-table-column>
        <el-table-column prop="mobile" label="电话" align="center"> </el-table-column>
        <el-table-column label="操作" style="width: 100%; white-space: nowrap" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="editTeam(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="conmitTeam()">确认</el-button>
      </span>
    </el-dialog>

    <!-- 新增球队 -->
    <el-dialog :visible.sync="addTeamVisible" width="50%" title="球队信息" @close="closeTeamDialog">
      <el-form ref="form" :model="teamInfo" label-width="80px">
        <el-form-item label="球队Logo">
          <f-image-upload
            v-model="teamInfo.logo"
            @input="changeLogo"
            style="margin-left: 0"
            :width="'100px'"
            :height="'100px'"
          ></f-image-upload>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="球队名称">
              <el-input v-model="teamInfo.teamName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="球队属性">
              <el-select v-model="teamInfo.teamProp" placeholder="请选择球队属性">
                <el-option
                  v-for="item in teamOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电话号码">
              <el-input v-model="teamInfo.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="球队实力">
              <el-select v-model="teamInfo.level" placeholder="请选择球队实力">
                <el-option
                  v-for="item in strength"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="约战次数">
              <el-input v-model="teamInfo.challengeCount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="队员人数">
              <el-input v-model="teamInfo.playerCount"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="球衣颜色">
              <el-input v-model="teamInfo.shirt" placeholder="本队球衣颜色"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="比赛备注">
              <el-input
                type="textarea"
                v-model="teamInfo.remark"
                :autosize="{ minRows: 2, maxRows: 5 }"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTeam">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import orderServeData from '../../apidata/orderServe.json';
import TopData from '../../common/components/normal/TopData.vue';
import { deepCopy } from '@/common/utils/common/index';
import planTime from '../../apidata/planTime.json';
import FImageUpload from '@/common/components/Upload/f-image-upload.vue';

export default {
  name: 'fieldDetail',
  components: {
    FImageUpload,TopData
  },
  data() {
    return {
      dialogVisible: false,
      formData: {},
      orderServeData: orderServeData,
      time: '',
      timeList: [],
      activeIndex: 0,
      scrollLeft: 0,
      showCalendar: false,
      venueLists: [],

      currentVenueInfo: {},
      planTime,
      planVisible: false,
      plan: {},
      // 最大、最小人数
      personVisible: false,
      maxAmount: undefined,
      minAmount: undefined,
      // 锁场
      lockVisible: false,
      lockTeamList: [
        { teamName: '', contact: '', mobile: '' },
        { teamName: '', contact: '', mobile: '' },
      ],
      // 约战
      aboutWarVisible: false,
      teamList: [],
      radio: '',
      addTeamVisible: false,
      teamInfo: {},
      saveBtnDisabled: false,

      // 枚举值，选项值
      bookingDetailTypeList: [
        { value: 1, label: '锁场' },
        // { value: 2, label: '约战' },
        { value: 0, label: '整场' },
        { value: 10, label: '散客' },
        // { value: 20, label: '飞盘' },
        // { value: -2, label: '闲置' },
      ],
      teamOption: [
        { value: '散客组队', label: '散客组队' },
        { value: '小区大队', label: '小区大队' },
        { value: '公司联队', label: '公司联队' },
        { value: '院校劲旅', label: '院校劲旅' },
        { value: '业余竞技', label: '业余竞技' },
        { value: '半职业队', label: '半职业队' },
      ],
      strength: [
        { value: '养生出汗', label: '养生出汗' },
        { value: '会踢能传', label: '会踢能传' },
        { value: '三俩球星', label: '三俩球星' },
        { value: '配合默契', label: '配合默契' },
        { value: '梦幻组合', label: '梦幻组合' },
        { value: '真刀真枪', label: '真刀真枪' },
      ],
    };
  },
  methods: {
    async editPlan(item, venue) {
      const currentDateTime = new Date().getTime();
      const beginDateTime = new Date(`${this.time} ${item.beginTime}`).getTime();
      if (currentDateTime - beginDateTime > 15 * 60 * 1000) {
        this.saveBtnDisabled = true;
      }
      const { res } = await this.$http.get(`/FieldVenue/ScheduleInfo/${item.scheduleId}`);
      if (res.isSuccess) {
        this.plan = res.data;
        this.planVisible = true;
      }
      this.currentVenueInfo = venue;
      // this.plan = deepCopy(item);
    },
    changeBookingDetailType() {
      if (this.plan.bookingDetailType == 10) {
        this.plan.max = this.currentVenueInfo.matchType * 3;
        this.plan.min = this.currentVenueInfo.matchType * 2;
      } else if (this.plan.bookingDetailType == 20) {
        this.plan.max = this.currentVenueInfo.matchType * 4;
        this.plan.min = this.currentVenueInfo.matchType * 2;
      } else {
        this.plan.max = undefined;
        this.plan.min = undefined;
      }
    },
    changePersonAmount() {
      this.maxAmount = this.plan.max;
      this.minAmount = this.plan.min;
      this.personVisible = true;
    },
    handleCancelPerson() {
      this.personVisible = false;
      this.maxAmount = undefined;
      this.minAmount = undefined;
    },
    handleConfirmPerson() {
      if (this.minAmount && this.maxAmount) {
        if (this.minAmount > this.maxAmount) {
          this.$showError('最小人数不得大于最大人数');
          return;
        }
      }
      this.personVisible = false;
      this.plan.max = this.maxAmount;
      this.plan.min = this.minAmount;
      this.maxAmount = undefined;
      this.minAmount = undefined;
    },

    // 约战弹窗
    async aboutWar() {
      // 当前点击行如果有数据，默认勾选radio
      if (this.plan.teamList && this.plan.teamList.length) {
        this.radio = this.plan.teamList[0].teamId;
      }
      const { res } = await this.$http.get('/UserTeam/FieldTeamList');
      if (res.isSuccess) {
        this.teamList = res.data || [];
        this.aboutWarVisible = true;
      }
    },
    // 确认该球队
    conmitTeam() {
      if (!this.radio) {
        this.$showError('请选择一支球队');
        return;
      }
      const selectTeamObj = this.teamList.find((el) => {
        return el.teamId === this.radio;
      });
      const checkTeam = [selectTeamObj];
      this.plan.teamList = checkTeam;
      this.aboutWarVisible = false;
    },
    // 编辑球队
    editTeam(row) {
      this.addTeamVisible = true;
      this.teamInfo = deepCopy(row);
    },
    //新增球队弹窗
    addTeam() {
      this.addTeamVisible = true;
    },
    // 保存球队
    saveTeam() {
      this.$confirm('确认保存球队信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { res } = await this.$http.post('/UserTeam/SaveFieldTeam', this.teamInfo);
        if (res.isSuccess) {
          this.$showSuccess('保存成功');
          this.addTeamVisible = false;
          this.aboutWarVisible = false;
          this.aboutWar();
        }
      });
    },
    closeTeamDialog() {
      this.addTeamVisible = false;
      this.teamInfo = {};
    },
    // 球队logo
    changeLogo(v) {
      this.teamInfo.logo = v;
    },

    // 关闭约战弹窗
    closeAboutWar() {
      this.radio = '';
    },

    //锁场弹窗
    fieldLock() {
      this.lockVisible = true;
      if (this.plan.teamList && this.plan.teamList.length) {
        this.lockTeamList = this.plan.teamList;
      }
    },
    handleSaveLock() {
      this.lockVisible = false;
      this.plan.teamList = this.lockTeamList;
      this.lockTeamList = [
        { teamName: '', contact: '', mobile: '' },
        { teamName: '', contact: '', mobile: '' },
      ];
    },
    closeLockDialog() {
      this.lockVisible = false;
      this.lockTeamList = [
        { teamName: '', contact: '', mobile: '' },
        { teamName: '', contact: '', mobile: '' },
      ];
    },

    async handleSavePlan() {
      const currentDateTime = new Date().getTime();
      const beginDateTime = new Date(`${this.time} ${this.plan.beginTime}`).getTime();
      if (currentDateTime - beginDateTime > 15 * 60 * 1000) {
        this.$showError('当前时间大于订单开始时间15分钟，不允许保存');
        return;
      }
      const params = {
        day: this.time,
        scheduleList: [this.plan],
      };
      const { res } = await this.$http.post('/FieldVenue/SaveSchedule', params);
      if (res.isSuccess) {
        this.planVisible = false;
        this.$showSuccess('保存成功');
        this.getOrderList();
      }
    },
    handleCancelPlan() {
      this.planVisible = false;
      this.plan = {};
      this.saveBtnDisabled = false;
    },

    // 拼场详情
    pitchDetail(item) {
      this.dialogVisible = true;
      this.formData = item;
      if (this.formData.bookType == 1) {
        this.formData.bookType = ' 拼场';
      }
      if (this.formData.bookType == 0) {
        this.formData.bookType = ' 整场';
      }
      if (this.formData.bookType == 2) {
        this.formData.bookType = '飞盘';
      }
      if (this.formData.status == 1) {
        this.formData.status = ' 即将开始';
      }
      if (this.formData.status == 2) {
        this.formData.status = ' 进行中';
      }
      if (this.formData.status == 3) {
        this.formData.status = '已结束';
      }
      if (this.formData.status == 99) {
        this.formData.status = '已订满';
      }
      this.formData.time = this.formData.beginTime + '-' + this.formData.endTime;
    },
    // 时间轴
    getTimeList() {
      let week = new Date().getDay();
      let arr = [];
      if (week == 1) {
        arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
      } else if (week == 2) {
        arr = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      } else if (week == 3) {
        arr = [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      } else if (week == 4) {
        arr = [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      } else if (week == 5) {
        arr = [-4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      } else if (week == 6) {
        arr = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8];
      } else if (week == 0) {
        arr = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7];
      }
      this.activeIndex = arr.findIndex((item) => item === 0);
      this.timeList = [];
      arr.forEach((item) => {
        this.timeList.push(this.getTime(item));
      });
      this.selectDay(this.activeIndex);
      console.log('初始化时间数组-----', this.timeList);
    },
    getTime(timeIndex) {
      let time = +new Date() + timeIndex * 24 * 60 * 60 * 1000;
      let date = new Date(time);
      let week = date.getDay();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let weekStr = '';
      if (week == 1) {
        weekStr = '星期一';
      } else if (week == 2) {
        weekStr = '星期二';
      } else if (week == 3) {
        weekStr = '星期三';
      } else if (week == 4) {
        weekStr = '星期四';
      } else if (week == 5) {
        weekStr = '星期五';
      } else if (week == 6) {
        weekStr = '星期六';
      } else if (week == 0) {
        weekStr = '星期日';
      }
      let timeData = {
        year,
        month,
        day,
        week,
        weekStr,
        timeIndex,
      };
      return timeData;
    },
    addTime() {
      let arr = [];
      let num = this.timeList[this.timeList.length - 1].timeIndex;
      for (let i = 1; i < 8; i++) {
        arr.push(num + i);
      }
      arr.forEach((item) => {
        this.timeList.push(this.getTime(item));
      });
    },
    scollHandle(type) {
      if (type == 1) {
        //向左
        if (this.scrollLeft - 75.6 > 0) {
          this.scrollLeft -= 75.6;
        } else {
          this.scrollLeft = 0;
        }
      } else {
        //向右
        this.scrollLeft += 75.6;
        this.addTime();
      }
    },
    selectDay(index) {
      this.activeIndex = index;
      let day = this.timeList[index];
      let str =
        day.year +
        '-' +
        (day.month < 10 ? '0' + day.month : day.month) +
        '-' +
        (day.day < 10 ? '0' + day.day : day.day);
      this.time = str;
      this.getOrderList();
    },

    // 获取某日订单列表
    async getOrderList() {
      const { res } = await this.$http.get(`/FieldVenue/FieldVenueSchedule/${this.time}`);
      if (res.isSuccess) {
        this.venueLists = res.data || [];
      }
    },
  },
  mounted() {
    this.getTimeList();
  },
};
</script>

<style scoped lang="scss">
.course-week {
  width: 100%;
  box-sizing: border-box;
    .chooseMonth {
      display: flex;
      justify-content: flex-end;
      width: 18%;
      div {
        flex: 1;
        display: flex;
        padding: 0 2%;
        white-space: nowrap;
        line-height: 1.2vh;
        box-sizing: border-box;

        .square {
          display: flex;
          width: 1vw;
          height: 1.6vh;
          border-radius: 0.5vh;
          box-sizing: border-box;
        }

        .title {
          display: flex;
          align-items: center;
          line-height: 1.6vh;
          margin-left: 0.5vw;
          font-size: 1.5vh;
          color: #fff;
        }

        .in_progress {
          background-color: #ccc;
        }

        .has_not_started {
          background-color: #aaaaaa;
        }

        .is_expired {
          background-color: #134f7a;
        }
      }
    }
  .venue_header {
    display: flex;
  }

  .venue-item {
    text-align: center;
    margin-right: 0.8vw;
  }

  .venue-area {
    width: 10.2vw;
    height: 8.8vh;
    margin: 1.2vh 0.1vw;
    margin-bottom: 0;
    border-radius: 1.2vh;
    font-size: 1.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .schedule-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .detail-item {
    width: 8.2vw;
    height: 12.8vh;
    margin-top: 1.5vh;
    border-radius: 1.2vh;
    display: flex;
    font-size: 1.8vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* 过期 */
  .delay {
    background-color: #ccc;
    color: #fff;
  }

  /* 订满 */
  .full {
    background-color: #ccc;
    color: #fff;
  }

  /* 可订 */
  .can-order {
    background-color: #134f7a;
    color: #333333;
    border: none;
    color: #fff;
  }

  .pitch-style {
    width: 3vw;
    height: 2.5vh;
    background-color: #f5a623;
    text-align: center;
    line-height: 2.5vh;
    color: #fff;
    border-radius: 0.5vh;
    margin-right: 0.5vw;
    font-size: 1.6vh;
  }

  .week_table {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  //日期轴
  .timeBox {
    width: 82vw;
    height: 8.8vh;
    font-size: 1.5vh;
    background-color: #5d9fe9;
    border-radius: 1vh;
    display: flex;
    overflow: hidden;

    .xiang {
      width: 3.6vw;
      line-height: 11.8vh;
      text-align: center;
    }

    .scrollBox {
      width: 100vw;
      overflow: hidden;

      .list {
        display: flex;
        transition: transform 1s;

        .timeItem {
          width: 10.8vw;
          height: 11.8vh;
          border-left: 0.1vw solid #a9afb9;
          padding: 1vh 0;

          .cart {
            width: 10.8vw;
            margin: 0 auto;
            line-height: 3.5vh;
            padding: 1.4vh 0;
            text-align: center;
            border-radius: 0.6vh;
            color: #fff;
          }

          .active {
            background: #fff;
            color: #222220;
          }
        }
      }
    }
  }

  .selectTime {
    font-size: 3vh;
    text-align: center;
  }
}

.orderType {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
}
.group-name {
  display: flex;
  align-items: center;

  .group {
    width: 5px;
    height: 22px;
    background-color: #134f7a;
    border-radius: 2px;
  }

  .name {
    margin-left: 10px;
    font-weight: 700;
    font-size: 20px;
  }
}
</style>
